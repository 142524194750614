import React from 'react'
import { Carousel } from 'react-bootstrap'
import teamimage1 from "../assets/teamphoto.png"
import teamimage2 from "../assets/teamphoto2.png"
import teamimage3 from "../assets/teamphoto3.png"
import { useNavigate } from 'react-router-dom'
import dotcircle from '../assets/dotcircle.png'
import bottomdot from '../assets/bottomdot.png'
import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import { Arabic } from "../Language/Arabic"

function Leadershipsec() {
    const Language = useSelector((state) => state.changer.Language);

    const navigate = useNavigate();
    return (
        <div class="row" dir={Language == "AR" ? "rtl" : "ltr"}>
            <div class="col">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="imgstyle ">
                            <Carousel>
                                <Carousel.Item interval={500}>
                                    <img
                                        className="d-block w-100"
                                        src={teamimage1}
                                        alt="Second slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={teamimage2}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={teamimage3}
                                        alt="Third slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="container">
                    <div class="row justify-content-start">
                        <div className='leadercontant'>
                            <h1>{Language == "AR" ? Arabic.LEADERSHIPTEAMDetail1 : "Meet our"}
                            </h1>
                            <h1>{Language == "AR" ? Arabic.LEADERSHIPTEAMDetail2 : "Management Team"}
                            </h1>
                            <div class="w-75 leaderlist" >
                                <ul >
                                    <li>{Language == "AR" ? Arabic.LEADERSHIPTEAML1 : "Avg. 20+ years of experience."}</li>
                                    <li>{Language == "AR" ? Arabic.LEADERSHIPTEAML2 : "Core team experience In IT services."}</li>
                                    <li>{Language == "AR" ? Arabic.LEADERSHIPTEAML3 : "Worked across a spectrum of business delivering value."}</li>
                                    <li>{Language == "AR" ? Arabic.LEADERSHIPTEAML4 : "Healthcare, Insurance, Education, Manufacturing, Government services and technical consulting experiences par excellence."}</li>
                                </ul>
                            </div>

                            <div className='bottomdot'>
                                <img class="rounded float-end" src={bottomdot} alt="bottomdot" />
                            </div>
                            <div class="pt-5  mydark">
                                <button type="button" onClick={() => { navigate("/career") }} class="btn btn-dark ">{Language == "AR"? Arabic.JOINUS : "JOIN US"}</button>
                            </div>
                        </div>
                        <div >
                            <img class="rounded float-end" src={dotcircle} alt="bottomdot" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Leadershipsec;