import React, { useState } from 'react'
import Carousel from 'react-multi-carousel';
import Leadershipfirst from './Leadershipfirst';
import Leadershipsec from './Leadershipsec';
import SubLeader1 from './SubLeader1';
import SubLeader2 from './SubLeader2';
import SubLeader3 from './SubLeader3';

import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import { Arabic } from "../Language/Arabic"

function LeaderShip() {

  const Language = useSelector((state) => state.changer.Language);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [data, setData] = useState(0)
  return (
    <div className='leader' dir={Language == "AR" ? "rtl" : "ltr"}>
      <div className='leaderheader'>
        <h1>{Language == "AR" ? Arabic.LEADERSHIPTEAM : "Leadership Team"}</h1>
      </div>
      {
        data === 1 ? <SubLeader1 datas={setData} /> :
          data === 2 ? <SubLeader2 datas={setData} /> :
            data === 3 ? <SubLeader3 datas={setData} /> :
              <div dir="ltr">
                <Carousel responsive={responsive} autoPlay={false} autoPlaySpeed={100000}>
                  <Leadershipsec />
                  <Leadershipfirst datas={setData} />
                </Carousel>
              </div>
      }
    </div>
  )
}

export default LeaderShip;