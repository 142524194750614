import React from 'react'
import group1 from "../assets/Mask group.png";
import group2 from "../assets/Mask group2.png"
import group3 from "../assets/Mask group3.png"
import LinkedIn from "../assets/LinkedIn.png"
import { useSelector } from "react-redux";

import {Arabic} from "../Language/Arabic"

function Leadershipfirst(props) {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div class="container" dir={Language == "AR"? "rtl" : "ltr"}>
            <div class="row justify-content-md-center">
                <div class="col col-lg-4 text-center">
                    <div>
                        <img src={group1} class="card-img-top leaderfirst " alt="..." />
                    </div>
                    <div class="card-body text-center">
                        <h5>{Language == "AR"? Arabic.Raghu : "Raghunadh Vaddadi"}</h5>
                        <p>{Language == "AR"? Arabic.RaghuDes : "Co-founder & CEO"}</p>
                        <img src={LinkedIn}
                            alt="LinkedIn"
                            onClick={() => window.open("https://www.linkedin.com/in/raghunadh-vaddadi-55a7105/")}
                        />
                        <div class="subcasebtn">
                            <button type="button" style={{ border: "1px solid black" }} class="btn btn-light btn-sm "
                                onClick={() => props.datas(1)}
                            >{Language == "AR"? Arabic.Knowmore : "Know More"}</button>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-4 text-center">
                    <div>
                        <img src={group2} class="card-img-top leaderfirst " alt="..." />
                    </div>
                    <div class="card-body text-center">
                        <h5>{Language == "AR"? Arabic.Muthu : "Muthuprakash Ravindran"}</h5>
                        <p>{Language == "AR"? Arabic.MuthuDes : "Vice President"}</p>
                        <img src={LinkedIn}
                            alt="LinkedIn"
                            onClick={() => window.open("https://www.linkedin.com/in/muthu-prakash-ravindran-pmp-csm-4570854/")}
                        />
                        <div class="subcasebtn">
                            <button type="button" style={{ border: "1px solid black" }} class="btn btn-light btn-sm "
                                onClick={() => props.datas(2)}
                            >{Language == "AR"? Arabic.Knowmore : "Know More"}</button>
                        </div>
                    </div>
                </div>
                <div class="col col-lg-4 text-center">
                    <div >
                        <img src={group3} class="card-img-top leaderfirst" alt="..." />
                    </div>
                    <div class="card-body text-center">
                        <h5>{Language == "AR"? Arabic.Mohan : "Mohanraj Polurbalu"}</h5>
                        <p>{Language == "AR"? Arabic.MohanDes : "Vice President"}</p>
                        <img src={LinkedIn}
                            alt="LinkedIn"
                            onClick={() => window.open(" https://www.linkedin.com/in/mohanrajpb/")}
                        />
                        <div class="subcasebtn">
                            <button type="button" style={{ border: "1px solid black" }} class="btn btn-light btn-sm "
                                onClick={() => props.datas(3)}
                            >{Language == "AR"? Arabic.Knowmore : "Know More"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Leadershipfirst