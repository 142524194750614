import React, { useEffect } from 'react'
import { Navbar } from "react-bootstrap";
import caseimg2 from "../assets/caseimg2.png";
import { useNavigate } from 'react-router-dom'
import Header from './Header';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

function Subcasestudy1() {
    const navigate = useNavigate();
    const Language = useSelector((state) => state.changer.Language);

    const goto = (path) => {
        navigate("/");
        localStorage.setItem("path", path);
    };

    useEffect(() => {
        if (window.location.pathname !== "/") {
            localStorage.setItem("path", window.location.pathname);
        } else {
            console.log("path", localStorage.getItem("path"));
            setTimeout(() => {
                let offsetTop = document.getElementById(localStorage.getItem("path")).offsetTop;
                window.scrollTo({
                    top: offsetTop - 100,
                    behavior: "smooth"
                });
            }, 1000);
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div dir={Language == "AR" ? "rtl" : "ltr"}>
            <Header />
            <Navbar
                collapseOnSelect
                expand="sm"
                bg="light"
                variant="light"
                fixed="top"
            ></Navbar>
            <div className='casestudy2' id="subcasestudy">
                <h1>{Language == "AR" ? Arabic.CASESTUDIES : "Case Studies"}</h1>
            </div>
            <div >
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-lg-12">
                            <div class="card" >
                                <div className='casestudyheader'>
                                    <h1>
                                        {Language == "AR" ? Arabic.CASESTUDIES2 : "Electronic Health Record"}
                                    </h1>
                                </div>
                                <div class="row justify-content-md-center">
                                    <div class="col-lg-8">
                                        <div >
                                            <img src={caseimg2} class="card-img " alt="..." />
                                            <div class="text-center " >
                                                <h5>{Language == "AR" ? Arabic.CASESTUDIES2Detail : "Blockchain’s open access would enable changes to an individual’s EMR (new imaging, procedures, labs) to be updated in real time on an EMR blockchain and instantly available to parties involved in an individual’s care (health care providers, pharmacies, insurance companies, the patient’s."}</h5>
                                            </div>
                                        </div>
                                        <div class=" subcasebtn">
                                            <button type="button" style={{ border: "1px solid black" }} class="btn btn-dark btn-lg " onClick={() => navigate("/contactform")}>{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                                        </div>
                                        <div class=" subcasebtn1">
                                            {window.location.pathname !== "/" ? (
                                                <div style={{ border: "1px solid black" }} class="btn btn-light " onClick={() => goto("/casestudy")}>{Language == "AR" ? Arabic.BACK : "Back"}</div>
                                            ) : (
                                                <button type="submit" style={{ border: "1px solid black" }} class="btn btn-light " to="casestudy" smooth={true} duration={100}>
                                                    {Language == "AR" ? Arabic.BACK : "Back"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div class="footerheader">
                    <div class="row  justify-content-around  ">
                        <div class=" techstyle nav nav-tabs mb-3">
                            <label onClick={() => navigate("/subcase")}>{Language == "AR" ? Arabic.CASESTUDIES1 : "Certificates Attestation & Management"}</label>
                        </div>
                        <div class=" techstyle">
                            <label style={{ color: "#1877F2" }}>{Language == "AR" ? Arabic.CASESTUDIES2 : "Electronic Health Record"}</label>
                        </div>
                        <div class=" techstyle">
                            <label onClick={() => navigate("/subcase2")}>{Language == "AR" ? Arabic.CASESTUDIES3 : "Supplychain Management On Blockchain"}</label>
                        </div>
                        <div class=" techstyle">
                            <label onClick={() => navigate("/subcase3")}>{Language == "AR" ? Arabic.CASESTUDIES4 : "Advance directive"}</label>
                        </div>

                        <div class=" techstyle">
                            <label onClick={() => navigate("/subcase4")}>{Language == "AR" ? Arabic.CASESTUDIES5 : "Professional Editing Tool"}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subcasestudy1;