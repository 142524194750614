import React from 'react'
import caseimg1 from "../assets/caseimg1.png";
import caseimg2 from "../assets/caseimg2.png";
import caseimg3 from "../assets/caseimg3.png";
import caseimg4 from "../assets/caseimg4.png";
import caseimg5 from "../assets/caseimg5.png";
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../Components/Casestudies.css'
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"


function Casestudy() {
  const Language = useSelector((state) => state.changer.Language);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div>
      <div className='casestudies'>
        <div class="row justify-content-around">
          <div class="col-11">
            <h1>{Language == "AR" ? Arabic.CASESTUDIES : "Case Studies"}</h1>
          </div>
        </div>
        <br />
        <br />
      </div>
      <Carousel responsive={responsive} class="casecaro">
        <div class=" cardstyle">
          <Link to="/subcase">
            <img src={caseimg1} class="cardimgmainpage" alt="..." />
          </Link>
          <div class="text-center " >
            <h5>{Language == "AR" ? Arabic.CASESTUDIES1 : "Certificate Attestation & Management"}</h5>
          </div>
        </div>
        <div class=" cardstyle">
          <Link to="/subcase1">
            <img src={caseimg2} class="cardimgmainpage" alt="..." />
          </Link >
          <div class="text-center" >
            <h5>{Language == "AR" ? Arabic.CASESTUDIES2 : "Electronic Health Record"}</h5>
          </div>
        </div>
        <div class=" cardstyle">
          <Link to="/subcase2">
            <img src={caseimg3} class="cardimgmainpage" alt="..." />
          </Link>
          <div class="text-center" >
            <h5>{Language == "AR" ? Arabic.CASESTUDIES3 : "Supplychain Management On Blockchain"}</h5>
          </div>
        </div>
        <div class=" cardstyle">
          <Link to="/subcase3">
            <img src={caseimg4} class="cardimgmainpage" alt="..." />
          </Link>
          <div class="text-center" >
            <h5>{Language == "AR" ? Arabic.CASESTUDIES4 : "Advance Directive"}</h5>
          </div>
        </div>
        <div class=" cardstyle">
          <Link to="/subcase4">
            <img src={caseimg5} class="cardimgmainpage" alt="..." />
          </Link>
          <div class="text-center" >
            <h5>{Language == "AR" ? Arabic.CASESTUDIES5 : "Professional Editing Tool"}</h5>
          </div>
        </div>
      </Carousel>
      <br />
      <br />
      <div class=" subcasebtn">
        <Link to="/subcase">
          <button type="button" style={{ border: "1px solid black" }} class="btn btn-dark ">{Language == "AR" ? Arabic.READMORE : "READ MORE"}</button>
        </Link>
      </div>
    </div>
  )
}
export default Casestudy;