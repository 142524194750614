import React from 'react'
import world_map from "../assets/bhmap.png"
import "../Components/Clock.css"
import bottomdot from '../assets/bottomdot.png'
import ThemedAnalogClock from 'themed-analog-clock';
import { useSelector } from "react-redux";
import {Arabic} from "../Language/Arabic"

const Clock = () => {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <>
            <div class="row clockmystyle" dir={Language == "AR"? "rtl" : "ltr"}>
                <div class="col-md-4 ">
                    <div class="container ">
                        <div class="row justify-content-md-center">
                            <div class="col col-lg-12 ">
                                <div className="clockstyle " >
                                    <ThemedAnalogClock timezoneName="Europe/Istanbul" size={200} />
                                    <div className=" clockname">
                                        <h4>{Language == "AR"? Arabic.BAHRAIN : "BAHRAIN"}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <img
                        className="d-block w-100"
                        src={world_map}
                        alt="Second slide"
                    />
                </div>
                <div className='bottomdot'>
                    <img class="rounded float-end" src={bottomdot} alt="bottomdot" />
                </div>
            </div>
        </>
    )
}

export default Clock