import React from 'react'
import group3 from "../assets/Mask group3.png";
import LinkedIn from "../assets/LinkedIn.png";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

function SubLeader3(props) {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-lg-12">
                    <div class="card subcontants" >
                        <div class="cardicon">
                            <HighlightOffIcon style={{ fontSize: 30, color: "#A9A4A4", cursor: "pointer" }}
                                onClick={() => props.datas(0)} />
                        </div>
                        <div class="container">
                            <div class="row subcontants ">
                                <div class="col-md-4 ">
                                    <div >
                                        <img src={group3} class="card-img " style={{ width: "15rem" }} alt="..." />
                                        <div class="text-center " >
                                            <br />
                                            <h4>{Language == "AR" ? Arabic.Mohan : "Mohanraj Polurbalu"}</h4>
                                            <p>{Language == "AR" ? Arabic.MohanDes : "Vice President"}</p>
                                            <img src={LinkedIn}
                                                alt="LinkedIn"
                                                onClick={() => window.open("https://www.linkedin.com/in/mohanrajpb//")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 leadercontantss">
                                    <div class="container ">
                                        <p>
                                            {Language == "AR" ? Arabic.MohanDesc : "Mohan is an early adopter of Technology disruptions, has a critical role as he handles most of our solutions and offerings. He has a deep understanding of the technology and is extremely patient and guides the team and our customers with dedication. He constantly surprises with better ways to implement design."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubLeader3