import '../Components/Career.css'
import "bootstrap/dist/css/bootstrap.min.css";
import grp1 from '../assets/grp1.png'
import grp2 from '../assets/grp2.png'
import Web1 from '../assets/Web1.jpg'
import Web2 from '../assets/Web2.jpg'
import Web3 from '../assets/Web3.jpg'
import Web4 from '../assets/Web4.jpg'
import Web5 from '../assets/Web5.jpg'
import { useState, useRef, useEffect } from "react";
import { Carousel } from 'react-bootstrap'
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"
import Header from './Header';

function Career() {
  const navigate = useNavigate();
  const Language = useSelector((state) => state.changer.Language);
  const form = useRef();
  const [Apply, setApply] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const CareerData1 = [
    {
      id: 1,
      Name: "مطور SQL",
      jobType: "دوام كامل",
      description: "نبحث عن مطور SQL يكون مسؤولاً عن تصميم قواعد البيانات وضمان استقرارها وموثوقيتها وأدائها.",
      jobLocation: "المكان: البحرين",
    },
    {
      id: 2,
      Name: "متدرب مبيعات",
      jobType: "متدرب",
      description: "كجزء من فريق تعزيز المبيعات، يوفر متدرب المبيعات أو حديث التخرج الأدوات والأصول والموارد لفريق المبيعات من أجل تحسين الكفاءة والنجاح.",
      jobLocation: "المكان: مومباي",
    },
    {
      id: 3,
      Name: "مهندس ديف أوبس",
      jobType: "دوام كامل",
      description: "بناء الأدوات والحلول والخدمات المصغرة المرتبطة بالنشر ومنصة العمليات لدينا والحفاظ عليها، مما يضمن أن تلبي جميعها معايير خدمة العملاء لدينا.",
      jobLocation: "المكان: تشيناي",
    },
    {
      id: 4,
      Name: "قائد تقني",
      jobType: "دوام كامل",
      description: "تولي قيادة جلسات العصف الذهني وجلسات المداولات لتطوير الحلول المحتملة لاحتياجات العمل أو مشاكله. وتحديد الفرص لتحسين العمليات.",
      jobLocation: "المكان: تشيناي"
    }
  ]
  const CareerData = [
    {
      id: 1,
      Name: "SQL Developer",
      description: "We are looking for a SQL developer who will be responsible for designing databases and ensuring their stability, reliability, and performance.",
      jobType: "Full Time",
      jobLocation: "Bahrain",
    },
    {
      id: 2,
      Name: "Sales Intern",
      description: "As part of the Sales Enablement team, the Sales Intern / Fresher provides the tools, assets and resources to the sales team in order to improve efficiency and success.",
      jobType: "Intern",
      jobLocation: "Mumbai",
    },
    {
      id: 3,
      Name: "DevOps Engineer",
      description: "Building and maintaining tools, solutions and microservices associated with deployment and our operations platform, ensuring that all meet our customer service standards.",
      jobType: "Full Time",
      jobLocation: "Chennai",
    },
    {
      id: 3,
      Name: "Technical Lead",
      description: "Lead brainstorming sessions & deliberation sessions to develop potential solutions for business needs or problems. Identify opportunities for process improvements.",
      jobType: "Full Time",
      jobLocation: "Chennai",
    },
  ];
  const formSubmit = (event) => {
    event.preventDefault();
    console.log("sending...")
    emailjs.sendForm('service_xaettm9', 'template_3vbdius', form.current, 'r_0GXz_VWXqWbWMZc');
  }

  const onFileChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div>
      <Header />
      <div class="container pt-4">
        <div class="row justify-content-md-start">
          <div class="d-flex justify-content-center career1">{Language == "AR" ? Arabic.CAREERS : "CAREERS"}</div>
        </div>
      </div>
      <div class="text-center career-info">
        {Language == "AR" ? Arabic.CAREERSDetail1 : "If you are looking for career opportunity in a dynamic organization you have come to the right place."}
        <br />
        {Language == "AR" ? Arabic.CAREERSDetail2 : "Browse through our vacant position or send us your resume to:info@rapidqube.bh | +973 32223926"}
      </div>
      <div class="container text-center pt-4">
        <div class="row">
          <div class="col pt-4">
            <img src={grp1} alt="career1" class=" grp-img" />
          </div>
          <div class="col pt-4">
            <img src={grp2} alt="career1" class=" grp-img" />
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-4">
              <div >
                <Carousel style={{ marginTop: "30px" }}>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100 "
                      src={Web1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src={Web2}
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src={Web3}
                      alt="Third slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src={Web4}
                      alt="Fourth slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src={Web5}
                      alt="Fifth slide"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        {Apply ? (
          <div class="card1">
            <div
              class="px-3"
              style={{
                fontWeight: "1000",
                fontSize: "22px",
                fontFamily: "inter",
              }}
            >
              Drop your CV here
            </div>
            <div
              class="pt-3 px-3"
              style={{ fontSize: "16px", fontFamily: "inter" }}
            >
              We will consider your Profile for future Jobs
            </div>
            <div class="d-flex justify-content-between m-1 px-3 pt-4 pb-4">
              <form ref={form}>
                <input type="file" id="myFile" name="file" onChange={onFileChange} />
                <button class="apply_btn" type="submit" onClick={formSubmit}>Apply Now</button>
              </form>
            </div>

          </div>
        ) : (
          <div class="container">
            <div class="row">
              {Language == "AR" ? CareerData1.map((career) => (
                <div class="col">
                  <div class="card2">
                    <div class="d-flex justify-content-between m-1 px-3 pt-4">
                      <div
                        class=""
                        style={{
                          fontWeight: "1000",
                          fontSize: "22px",
                          fontFamily: "inter",
                        }}
                      >
                        {career.Name}
                      </div>
                      <div
                        class="pt-2"
                        style={{
                          fontWeight: "500",
                          fontSize: "12px",
                          fontFamily: "inter",
                        }}
                      >
                        {career.jobType}
                      </div>
                    </div>
                    <div
                      class="pt-2  px-3 h-50"
                      style={{ fontSize: "12px", fontFamily: "inter" }}
                    >
                      {career.description}
                    </div>
                    <div class="d-flex justify-content-between px-3">
                      <div>
                        <img src="https://img.icons8.com/material-outlined/24/000000/marker.png" />
                        {career.jobLocation}
                      </div>
                      <div class="text-end px-3">
                        <button class="apply_btn" onClick={() => navigate("/contactform")}>
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )) :
                CareerData.map((career) => (
                  <div class="col">
                    <div class="card2">
                      <div class="d-flex justify-content-between m-1 px-3 pt-4">
                        <div
                          class=""
                          style={{
                            fontWeight: "1000",
                            fontSize: "22px",
                            fontFamily: "inter",
                          }}
                        >
                          {career.Name}
                        </div>
                        <div
                          class="pt-2"
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            fontFamily: "inter",
                          }}
                        >
                          {career.jobType}
                        </div>
                      </div>
                      <div
                        class="pt-2  px-3 h-50"
                        style={{ fontSize: "12px", fontFamily: "inter" }}
                      >
                        {career.description}
                      </div>
                      <div class="d-flex justify-content-between px-3">
                        <div>
                          <img src="https://img.icons8.com/material-outlined/24/000000/marker.png" />
                          {career.jobLocation}
                        </div>
                        <div class="text-end px-3">
                          <button class="apply_btn" onClick={() => navigate("/contactform")}>
                            Apply Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className='backcareer d-flex justify-content-center'>
              <button type="submit" class="btn btn-dark " onClick={() => navigate(-1)}>
                {Language == "AR" ? Arabic.BACK : "Back"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Career;