import React from 'react'
import group1 from "../assets/Mask group.png";
import LinkedIn from "../assets/LinkedIn.png"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

function SubLeader1(props) {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card subcontants" >
                        <div class="cardicon">
                            <HighlightOffIcon style={{ fontSize: 30, color: "#A9A4A4", cursor: "pointer" }}
                                onClick={() => props.datas(0)} />
                        </div>
                        <div class="container">
                            <div class="row subcontants ">
                                <div class="col-md-4">
                                    <div class="text-center ">
                                        <img src={group1} class="card-img " style={{ width: "15rem" }} alt="..." />
                                        <div class="text-center subleader" >
                                            <br />
                                            <h4>{Language == "AR" ? Arabic.Raghu : "Raghunadh Vaddadi"}</h4>
                                            <p>{Language == "AR" ? Arabic.RaghuDes : "Co-founder & CEO"}</p>
                                            <img src={LinkedIn}
                                                alt="LinkedIn"
                                                onClick={() => window.open("https://www.linkedin.com/in/raghunadh-vaddadi-55a7105/")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 leadercontantss">
                                    <div class="container  ">
                                        <p>
                                            {Language == "AR" ? Arabic.RaghuDesc : "As the CEO of RapidQube, Raghunadh Vaddadi is responsible for planning and strategizing the future of the Company – he decides and designs the Road Ahead for RapidQube. Highly motivated, energetic, impartial and committed to the well-being of all its Employees – has the fantastic knack of developing and nurturing human assets and make them ready for the Business Challenges from all quarter of life. The best part of his Professional Work Pattern is that he always gets the right pulse for an opportunity and has the ability to make it big for RapidQube. For RapidQube, he is the Natural Leader and always stands tall in the face of all complex scenarios and gives clear direction and guidance to the management team, whilst at the same time allowing them the complete autonomy to operate successfully with enough liberty & leverage."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubLeader1