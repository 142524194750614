import React from 'react'
import phone from '../assets/phone.png'

import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import '../Components/Contactus.css';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

const Contactus = () => {
    const navigate = useNavigate();
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div style={{ backgroundColor: 'white' }} dir={Language == "AR" ? "rtl" : "ltr"}>
            <br id='contactus' />
            <div>
                <div class="row contact">
                    <div class="col-md-8">
                        <div class="card-body">
                            <h2 class="card-title title">{Language == "AR" ? Arabic.CONTACTUS : "CONTACT US"}</h2>
                            <h4 class="card-title title1">{Language == "AR" ? Arabic.RapidQube : "RapidQube Digital Solutions WLL"}</h4>
                            <p class="card-text">{Language == "AR" ? Arabic.address : "Office #1114, Bahrain Financial Harbour Masterplan 11th floor, Harbour Towers - East, Building 1398, Road 4626,Block 346, Seafront Manama, Kingdom of Bahrain"}</p>
                            <div className='phone1 d-flex align-items-center'>
                                <img src={phone} alt="phone" />
                                <span dir="ltr" className='pe-1'>&nbsp; +973 32223926</span>
                            </div>
                            <div className='phone1 d-flex align-items-center'>
                                <img src="https://img.icons8.com/external-smashingstocks-detailed-outline-smashing-stocks/66/null/external-landline-networking-smashingstocks-detailed-outline-smashing-stocks.png" style={{width:"20px"}}/>
                                <span dir="ltr" className='pe-1'>&nbsp; 16501154</span>
                            </div>
                            <div class="col-md-4 pt-2 ContactBtn">
                                <Button variant="dark career" onClick={() => { navigate("/contactform") }}>{Language == "AR" ? Arabic.CONTACTUS : "CONTACT US"}</Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default Contactus;