import React from 'react'
import mylogo from '../assets/mylogo.png';
import Facebook from '../assets/Facebook.png';
import Twitter from '../assets/Twitter.png';
import LinkedIn from '../assets/LinkedIn.png';
import utube from '../assets/utube.png';
import Instagram from '../assets/Instagram.png'
import '../Components/Info.css'
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

const Info = () => {
    const Language = useSelector((state) => state.changer.Language);
    return (
        <>
            <footer dir={Language == "AR" ? "rtl" : "ltr"}>
                <div class="row" id='footerheading'>
                    <p class="heading">{Language == "AR" ? Arabic.GREETING : "we would love to hear from you"}</p>
                    <div class="col-12">
                        <div>
                            <p class="emailheading">info@rapidqube.bh</p>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="left box">
                        <div class="upper">
                            <div class="topic text-align center">{Language == "AR" ? Arabic.ABOUTUS : "ABOUT US"}</div>
                            <p className='parafooter'>{Language == "AR" ? Arabic.MOTTO : "Delivering solutions leveraging “best in class” digital technology to enhance your business"}
                            </p>
                        </div>
                        <div class="lower">
                            <div class="d-flex justify-content-around">
                                <img src={Facebook}
                                    alt="Facebook"
                                    onClick={() => window.open("https://www.facebook.com/RapidQubeBahrain", "_blank")} />
                                <img src={Twitter}
                                    alt="Twitter"
                                    onClick={() => window.open("https://twitter.com/RapidqubeB", "_blank")}
                                />
                                <img src={LinkedIn}
                                    alt="LinkedIn"
                                    onClick={() => window.open(" https://www.linkedin.com/company/rapidqube-bahrain", "_blank")}
                                />
                                <img src={utube}
                                    alt="utube"
                                    onClick={() => window.open("https://www.youtube.com/channel/UCkwqExwAISY_9yYinPUWYpg")}
                                />
                                <img src={Instagram}
                                    alt="Instagram"
                                    onClick={() => window.open("https://www.instagram.com/rapidqubebahrain/")}
                                />
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="middle box ">
                        <div class="topic">{Language == "AR" ? Arabic.OurLinks : "OUR LINKS"}</div>
                        <div><a href="#">{Language == "AR" ? Arabic.ABOUTUS : "About Us"}</a></div>
                        <div><a href="#product">{Language == "AR" ? Arabic.Services : "Services"}</a></div>
                        <div><a href="#leadership">{Language == "AR" ? Arabic.Team : "Team"}</a></div>
                        <div><a href="#contactus">{Language == "AR" ? Arabic.ContactUs : "Contact Us"}</a></div>
                    </div>
                    <div class="right box ">
                        <div class="topic">{Language == "AR" ? Arabic.OURSERVICES : "SERVICES"}</div>
                        <div><a href="#services">{Language == "AR" ? Arabic.PRODUCTS : "Products"}</a></div>
                        <div><a href="#services">{Language == "AR" ? Arabic.AdvisoryConsulting : "Advisory Consulting"}</a></div>
                        <div><a href="#services">{Language == "AR" ? Arabic.ProfessionalServices : "Professional Services"}</a></div>
                    </div>
                    <div class="right box">
                        <div class="topic">{Language == "AR" ? Arabic.OTHERLINKS : "OTHER LINKS"}</div>
                        <div><a href="/privacyPolicy">{Language == "AR" ? Arabic.Privacypolicy : "Privacy Policy"}</a></div>
                        <div><a href="/Terms">{Language == "AR" ? Arabic.TermsConditions : "Terms & Conditions"}</a></div>
                    </div>
                </div>
                <hr />
                <br />
                <div class="bottom text-center ps-5">
                    <h6>
                        {Language == "AR" ? Arabic.Copyright : "Copyright © 2022 RapidQube Digital Solutions WLL. All Rights Reserved."} <a style={{ color: 'blue' }} href='/privacyPolicy'>{Language == "AR" ? Arabic.Privacypolicy : "Privacy Policy"}</a>
                    </h6>
                </div>
                <br />
            </footer>
        </>
    )
}
export default Info








