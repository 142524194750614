import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    Language: "EN"
}

export const ChangeLanguage = createSlice({
    name: 'changer',
    initialState,
    reducers: {
        arabic: (state) => {
            state.Language = "AR";
        },
        english: (state) => {
            state.Language = "EN";
        }
    }
})

export const { arabic, english } = ChangeLanguage.actions;

export default ChangeLanguage.reducer;