import { Carousel } from 'react-bootstrap'
import '../Components/Screen3.css'
import React from 'react'
import p1 from '../assets/p1.png'
import p4 from '../assets/p4.png'
import p5 from '../assets/p5.png'
import sidedot from '../assets/sidedot.png'
import bottomdot from '../assets/bottomdot.png'
import rectangle from '../assets/rectangle.png'
import doubleline from '../assets/doubleline.png'

import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import {Arabic} from "../Language/Arabic"

function Screen3() {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div dir={Language == "AR"? "rtl" : "ltr"}>
            <div>
                <div>
                    <div>
                        <div style={{ textAlign: "Center" }}> <label className="service">{Language == "AR"? Arabic.SERVICES : "SERVICES"}</label></div>
                        <div className="service-help mb-5" >
                            <label className='help'>
                                {Language == "AR"? Arabic.HELPYOU : "How can we help you?"}
                            </label>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-md-5 ">
                            <div class="row ">
                                <div class="col ">
                                    <div className='d-md-flex'>
                                        <img src={rectangle} alt="rectangle" width={295} className='parent2' />
                                    </div>
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="imgstyle ">
                                                <Carousel>
                                                    <Carousel.Item interval={500}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={p1}
                                                            alt="Second slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={p4}
                                                            alt="Third slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={p5}
                                                            alt="Third slide"
                                                        />
                                                    </Carousel.Item>
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 align-self-center">
                            <div className='flex-column align-self-center mt-5'>
                                <div >
                                    <p className='needs'>{Language == "AR"? Arabic.service1 : "RapidQube Services to meet the right customer needs"}</p>
                                </div>
                                <br />
                                <div className='qutoesandpara'>
                                    <div>
                                        <img src={doubleline} alt="qutoes" className='parent3' />
                                    </div>
                                    <div style={{ position: 'absolute' }}>
                                        <p className='razor'>{Language == "AR"? Arabic.service2a : `We focus on 3 distinct services and delivering`}</p>
                                        <br />
                                        <p className='razor' style={Language == "AR"? {float: "right"} : null}>{Language == "AR"? Arabic.service2b : `value through new-age technologies`}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sidedot1'>
                                <img class="rounded float-end" src={sidedot} alt="sidedot" />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className='bottomdot1'>
                <img class="rounded float-end bot" src={bottomdot} alt="bottomdot" />
            </div>
        </div>
    )
}

export default Screen3