export const Arabic = {
    About: "نحن",
    HomePage: "الصفحة الرئيسية",
    Detail1: "نحن رابيدكيوب",
    Detail2: `تقديم الحلول`,
    Detail3: "للاستفادة من التكنولوجيا الرقمية",
    Detail4: `"الأفضل في فئتها"`,
    Detail5: "لتعزيز أعمالك",
    Detail6: "هيا نصنع شيئًا مدهشًا معًا.",
    CONTACT: "اتصل",
    CONTACTUS: "اتصل بنا",
    SERVICES: "الخدمات",
    HELPYOU: "كيف يمكننا مساعدتك؟",
    service1: "خدمات رابيدكيوب لتلبية الاحتياجات المناسبة لدى العملاء",
    service2a: "نركز على 3 خدمات مميزة ونقدم القيمة من خلال تقنيات العصر الجديد",
    service2b: "خدمات مميزة ونقدم القيمة من خلال",
    PRODUCTS: "المنتجات",
    Knowmore: "معرفة المزيد",
    PRODUCTSDetail: "تم تصميم منتجاتنا وإنشائها بطريقة متقنة على مر السنين لتناسب احتياجات الأعمال المتنوعة لدى عملائنا، حيث تم تصميمها باستخدام المحور الأساسي لسلسلة الكتل. لدينا العديد من العملاء الذين يستخدمون منتجاتنا في صناعات متنوعة بداية من الرعاية الصحية ووصولاً إلى التصنيع والخدمات الحكومية.",
    ADVISORYCONSULTING: "الاستشارات",
    ADVISORYCONSULTINGDetail: "يقدم الخبراء المستشارون لدينا مجموعة من الاستشارات المتعمقة لعملائنا في مجالات متنوعة:",
    ADVISORYCONSULTINGL1: "استشارات الأعمال",
    ADVISORYCONSULTINGL2: "استشارات التكنولوجيا",
    ADVISORYCONSULTINGL3: "التحول الرقمي",
    ADVISORYCONSULTINGL4: "التدريب على التقنيات الناشئة",
    PROFESSIONALSERVICES: "الخدمات الاحترافية",
    PROFESSIONALSERVICESDetail: "نقدم أيضًا حلول توظيف متخصصة لعملائنا الكرام في المجالات التالية:",
    PROFESSIONALSERVICESL1: "علوم البيانات",
    PROFESSIONALSERVICESL2: "تحليلات الأعمال",
    PROFESSIONALSERVICESL3: "التحليلات الرقمية",
    PROFESSIONALSERVICESL4: "علوم الحياة",
    PROFESSIONALSERVICESL5: "التحول الرقمي",
    INDUSTRIALPROCESSAUTOMATION: "أتمتة العمليات الصناعية",
    INDUSTRIALPROCESSAUTOMATIONDetail: "نملك من الخبرات ما يُمكننا لإنشاء عمليات تصنيع بلا عيوب، وتقديم ملاحظات في الوقت الفعلي لتغييرات المتطلبات، وتوفير المعلومات من مصدر واحد. تشمل مزايا ذلك ما يلي:",
    INDUSTRIALPROCESSAUTOMATIONL1: "التقاط معلومات التكلفة على نحو أكثر دقة",
    INDUSTRIALPROCESSAUTOMATIONL2: "زيادة مدة التشغيل",
    INDUSTRIALPROCESSAUTOMATIONL3: "دمج أنشطة سير العمل غير الورقية",
    INDUSTRIALPROCESSAUTOMATIONL4: "إمكانية تتبع عمليات التصنيع",
    BLOCKCHAIN: "سلسلة الكتل",
    BLOCKCHAINDetail: "التأمين الآلي، سجلات الرعاية الصحية، سلسلة التوريد، السلاسل الحكومية، الأصول الرقمية والتخزين، الرموز والتبادلات.",
    ARTIFICIALINTELLIGENCE: "الذكاء الاصطناعي",
    ARTIFICIALINTELLIGENCEDetail: "روبوتات الدردشة، الروبوتات، المساعدة الافتراضية، الآلات المؤتمتة، التعلم الآلي، التحليل التنبئي.",
    OMNICHANNEL: "قناة OMNI",
    OMNICHANNELDetail: "تطبيقات التجارة الإلكترونية، حلول الدفع، تطبيقات السوق، التجارة الاجتماعية، الصناعة المصرفية كخدمة، تطبيقات نقاط البيع",
    INTERNETOFTHINGS: "انترنت الأشياء",
    INTERNETOFTHINGSDetail: "تكامل الأجهزة، التتبع والتحليل، التحكم عن بعد، SmartThings، تطبيقات التنقل، الأداء والتقييم.",
    BIGDATA: "البيانات الكبيرة",
    BIGDATADetail: "تحليلات البيانات، إدارة البيانات، ذكاء الأعمال، البرمجة اللغوية العصبية، التحليلات التنبؤية، التعلم الآلي، تخزين البيانات.",
    ARVR: "الواقع المعزز / الواقع الافتراضي",
    ARVRDetail: "التعرف البصري على الحروف، الواقع الافتراضي، الرسوم المتحركة وثلاثية الأبعاد، أجهزة الاستشعار والتحكم، دفتر الحسابات والرموز، السياق والتعلم، معالجة الصور.",
    LEADERSHIPTEAM: "فريق القيادة",
    LEADERSHIPTEAMDetail1:"قابل فريق",
    LEADERSHIPTEAMDetail2: "الإدارة لدينا",
    JOINUS:"انضم إلينا",
    LEADERSHIPTEAML1: "أكثر من 20 سنة من الخبرة في المتوسط.",
    LEADERSHIPTEAML2: "خبرة الفريق الأساسية في خدمات تكنولوجيا المعلومات.",
    LEADERSHIPTEAML3: "عمل الفريق عبر مجموعة من الأعمال التي تقدم القيمة.",
    LEADERSHIPTEAML4: "الرعاية الصحية، والتأمين، والتعليم، والتصنيع، والخدمات الحكومية، وخبرات الاستشارات الفنية بامتياز.",
    MissViss1: "وعدنا لأصحاب المصلحة",
    MissViss2: "تكوين معارف مفيدة بين الأعمال والأشخاص بطريقة مدعومة رقميًا",
    MISSION: "المهمة",
    MISSIONDetail: "بوصفنا مستشارًا موثوقًا به، تتمثل مهمتنا في إنشاء منتجات وحلول متطورة تساعد في مواجهة تحديات الأعمال المعقدة من خلال بناء تطبيقات وأنظمة رقمية آمنة وفعالة يمكن الاعتماد عليها بالإضافة إلى بنية أساسية مستدامة وحديثة.",
    VISION: "الرؤية",
    VISIONDetail: "تركز رؤيتنا على المساعدة في بناء الأعمال المستدامة التي يمكنها تبني الاحتياجات المتطورة بدعم من التقنيات التحويلية التي تطلق العنان لنمو الشركات والمستثمرين في جميع أنحاء العالم.",
    CASESTUDIES: "دراسات الحالة",
    CASESTUDIES1: "تصديق الشهادات وإدارتها",
    CASESTUDIES1Detail: "الشهادات التي يتم إصدارها غير قابلة للتغيير مع التحقق من بيانات الاعتماد والطابع الزمني وهو ما يوفر إجابة عن الهوية الصحيحة للمستندات. يوفر منتجنا عملية بسيطة ويضمن توفر المسؤولين الحكوميين لجعل رحلة المرشحين بسيطة وخالية من المتاعب.",


    Name: "الاسم:",
    Email: "البريد الإلكتروني:",
    Mobilenumber: "رقم الهاتف المحمول:",
    Organization: "المنظمة:",
    Intention: "الغرض:",
    Intention1: "مناقشة",
    Intention2: "استعلام",
    Intention3: "وظيفة",
    Message: "الرسالة:",
    SEND: "إرسال",
    BACK: "رجوع",

    CASESTUDIES2: "السجل الصحي الإلكتروني",
    CASESTUDIES2Detail: "الوصول المفتوح إلى سلسلة الكتل قد يؤدي إلى تمكين التغييرات على EMR للفرد (التصوير الجديد، والإجراءات، والمختبرات) ليتم تحديثها في الوقت الفعلي على إحدى سلاسل الكتل لـ EMR ولتكون متاحة على الفور للأطراف المشاركة في رعاية الفرد (مزودي الرعاية الصحية، والصيدليات، وشركات التأمين، وخدمات المرضى.",

    CASESTUDIES3: "إدارة سلسلة التوريد على سلسلة الكتل",
    CASESTUDIES3Detail: "تساعد التكنولوجيا المبتكرة في حل هذه المشكلات من خلال توفير معلومات الأعمال الحيوية عبر الشبكة، وتحسين تحليل البيانات في الوقت الفعلي، وتمكين مستوى أفضل للتعاون بين الإدارات والشركات. منتجنا الذي تم إنشاؤه باستخدام مزيج من إنترنت الأشياء والذكاء الاصطناعي وسلسلة الكتل يساعدك على التتبع.",

    CASESTUDIES4: "التوجيه المسبق",
    CASESTUDIES4Detail: "يساعد المرضى على التحكم في الرعاية الطبية الخاصة بهم من خلال توفير التوجيهات مسبقًا للأطباء. ويتم توزيع بيانات المريض عبر شبكة سلسلة الكتل المسموح بها لتسهيل الوصول والمشاركة.",

    CASESTUDIES5: "أداة التحرير الاحترافية",
    CASESTUDIES5Detail: "يوفر التطبيق جميع الميزات المتطورة والشاملة المتوفرة في أدوات الكتابة المتعددة، وذلك ضمن منصة واحدة للتسهيل على الكتاب والباحثين والطلاب وخفض نفقاتهم على المنصات المختلفة من أجل ميزات متعددة.",

    READMORE: "قراءة المزيد",
    TECHNOLOGIES: "التقنيات",
    TechStacks: "تكدسات التكنولوجيا",
    TechStack1: "ويب 3.0",
    TechStack2: "الذكاء الاصطناعي",
    TechStack3: "إنترنت الأشياء",
    TechStack4: "CloudOps",

    Testimonials: "شهادات التزكية",
    ClientFeedbacks: "تقييمات العملاء",

    CONTACTUS: "اتصل بنا",
    RapidQube: "شركة رابيدكيوب للحلول الرقمية ذ.م.م",
    address: "مكتب رقم 1114، المخطط الرئيسي لمرفأ البحرين المالي، الطابق 11، أبراج المرفأ - الشرق، مبنى رقم 1398، طريق 4626، مجمع 346، الواجهة البحرية في المنامة، مملكة البحرين",
    BAHRAIN:"البحرين",
    GREETING: "يسرنا أن نسمع منك",

    ABOUTUS: "من نحن",

    MOTTO: `نقدم حلولاً للاستفادة من التكنولوجيا الرقمية "الأفضل في فئتها" لتعزيز أعمالك`,
    OurLinks: "روابطنا",
    AboutUs: "من نحن",
    Services: "الخدمات",
    Team: "الفريق",

    ContactUs: "اتصل بنا",
    OURSERVICES: "خدماتنا",

    AdvisoryConsulting: "خدمات استشارية",
    ProfessionalServices: "خدمات احترافية",
    OTHERLINKS: "روابط أخرى",
    Privacypolicy: "سياسة الخصوصية",
    TermsConditions: "الشروط والأحكام",
    Copyright: "حقوق النشر © 2022 شركة رابيدكيوب للحلول الرقمية ذ.م.م. كل الحقوق محفوظة. ",
    CAREERS: "وظائف",
    CAREERSDetail1:"إذا كنت تبحث عن فرصة وظيفية في مؤسسة فعالة، فإنك في المكان",
    CAREERSDetail2:"المناسب. تصفح الوظائف الشاغرة أو أرسل لنا سيرتك الذاتية إلى",

    JOB1: "مطور SQL",
    JOB1Time: "دوام كامل",
    JOB1Desc: "نبحث عن مطور SQL يكون مسؤولاً عن تصميم قواعد البيانات وضمان استقرارها وموثوقيتها وأدائها.",
    JOB1Place: "المكان: البحرين",

    JOB2: "متدرب مبيعات",
    JOB2Time: "متدرب",
    JOB2Desc: "كجزء من فريق تعزيز المبيعات، يوفر متدرب المبيعات أو حديث التخرج الأدوات والأصول والموارد لفريق المبيعات من أجل تحسين الكفاءة والنجاح.",
    JOB2Place: "المكان: مومباي",

    JOB3: "مهندس ديف أوبس",
    JOB3Time:  "دوام كامل",
    JOB3Desc: "بناء الأدوات والحلول والخدمات المصغرة المرتبطة بالنشر ومنصة العمليات لدينا والحفاظ عليها، مما يضمن أن تلبي جميعها معايير خدمة العملاء لدينا.",
    JOB3Place: "المكان: تشيناي",

    JOB4: "قائد تقني",
    JOB4Time: "دوام كامل",
    JOB4Desc: "تولي قيادة جلسات العصف الذهني وجلسات المداولات لتطوير الحلول المحتملة لاحتياجات العمل أو مشاكله. وتحديد الفرص لتحسين العمليات.",
    JOB4Place: "المكان: تشيناي",

    Raghu: "راغوناد فدادي",
    RaghuDes: "المؤسس المشارك والرئيس التنفيذي",
    RaghuDesc: "بصفته الرئيس التنفيذي لشركة رابيدكيوب، فإن راغوناد فدادي هو المسؤول عن التخطيط ووضع الاستراتيجيات لمستقبل الشركة - فهو يضع خطة شركة رابيدكيوب ويرسمها - وهو شخصية متحمسة للغاية وحيوية ومحايدة وملتزمة برفاهية جميع الموظفين، كما أنه يتمتع بموهبة رائعة لتنمية الموارد البشرية ورعايتها وجعلها جاهزة لتحديات الأعمال بشتى أنواعها. يتمثل أفضل جزء من نمط عمله المهني في أنه دائمًا ما يكون لديه شعور مناسب بأي فرصة، ولديه القدرة على استثمار تلك الفرصة جيدًا لصالح شركة رابيدكيوب. وهو يعد بالنسبة إلى رابيدكيوب بمثابة القائد الطبيعي ويقف شامخًا دائمًا في مواجهة جميع السيناريوهات المعقدة ويعطي تعليمات وتوجيهات واضحة لفريق الإدارة وفي نفس الوقت يسمح له بالاستقلالية الكاملة للعمل بنجاح في أجواء كافية من الحرية والنفوذ.",

    Muthu: "موثوبراكاش رافيندران",
    MuthuDes: "نائب الرئيس",
    MuthuDesc:"يُعد فهمه لاحتياجات العمل ومواضع الشكوى لدى العملاء أمرًا طبيعيًا نظرًا لما لديه من سنوات خبرة في تقديم أنظمة دقيقة لمختلف العملاء وفي كونه جزءًا من الحل لمستويات مختلفة من فهم كيفية عمل الإنتاج في الأعمال المختلفة. والآن، من خلال عمله مع الكثير من الوكالات الحكومية والعملاء في جميع أنحاء العالم، يستطيع موثو مساعدتهم في إدارة أعمالهم بكفاءة وفي تقديم خدماتهم كما هو متوقع",

    Mohan: "موهانراج بولربالو",
    MohanDes: "نائب الرئيس",
    MohanDesc: "موهان هو من أوائل المتحمسين للتطورات التكنولوجية، ويقدم دورًا حيويًا من خلال تعامله مع معظم الحلول والعروض التي نقدمها، ولديه فهم عميق للتكنولوجيا، كما أنه صبور للغاية ويوجه الفريق وعملائنا بإخلاص، ويقدم باستمرار طرقًا أفضل لتنفيذ الخطط على نحو مذهل"
}