import React from 'react'
import box from '../assets/box.svg';
import '../Components/Landingpage.css'
import pic1 from '../assets/pic1.png'
import pic2 from '../assets/pic2.png'
import pic3 from '../assets/pic3.png'
import smalldot from '../assets/smalldot.png'
import dotted from '../assets/dotted.png'
import { Carousel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import { Arabic } from "../Language/Arabic"


const Landingpage = () => {
    const Language = useSelector((state) => state.changer.Language);
    const navigate = useNavigate();
    return (
        <div class="row" dir={Language == "AR" ? "rtl" : "ltr"}>
            <div class="col-md-5">
                <div className='Services'>
                    <div class="card-body cardbody">
                        <h5 class="card-title RQtitle1 mt-4">{Language == "AR" ? Arabic.Detail1 : "We’re RapidQube"}</h5>
                        <p class="card-text1 mt-4" style={Language == "AR" ? { textAlign: "right" } : { textAlign: "left" }}>{Language == "AR" ? Arabic.Detail2 : "Delivering solutions"}</p>
                        <p class="card-text1" style={Language == "AR" ? { textAlign: "right" } : { textAlign: "left" }}>{Language == "AR" ? Arabic.Detail3 : "leveraging “best in class”"}</p>
                        <p class="card-text1" style={Language == "AR" ? { textAlign: "right" } : { textAlign: "left" }}>{Language == "AR" ? Arabic.Detail4 : "digital technology to"}</p>
                        <p class="card-text1" style={Language == "AR" ? { textAlign: "right" } : { textAlign: "left" }}>{Language == "AR" ? Arabic.Detail5 : "enhance your business"}</p>
                        <h5 class="card-title mt-4 RQtitle1">{Language == "AR" ? Arabic.Detail6 : "Let's make something amazing together."}</h5>
                        <div style={Language == "AR" ? { textAlign: "right" } : { textAlign: "left" }}>
                            <button type="button" onClick={() => { navigate("/contactform") }} class="btn btn-dark mydark">{Language == "AR" ? Arabic.CONTACTUS : "Contact Us"}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'white' }} class="col-md-7" >
                <div className='d-md-flex myparent'>
                    <img src={box} alt="box" width={350} className='parent1' />
                </div>
                <div class="container">
                    <div class="row justify-content-end">
                        <div class="col-10">
                            <div >
                                <Carousel style={{ marginTop: "30px" }}>
                                    <Carousel.Item interval={1000}>
                                         <img
                                            className="d-block w-100"
                                            src={pic1}
                                            alt="First slide"
                                            
                                        />

                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img
                                            className="d-block w-100"
                                            src={pic2}
                                            alt="Second slide"
                                        />
                                    </Carousel.Item>
                                    <Carousel.Item interval={1000}>
                                        <img
                                            className="d-block w-100"
                                            src={pic3}
                                            alt="Third slide"
                                        />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dotted' >
                    <img src={dotted} alt="dotted" className='parent2' />
                    <img class="rounded float-end" src={smalldot} alt="smalldot" width={50} />
                </div>
            </div>
        </div>
    )
}

export default Landingpage


