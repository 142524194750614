import React, { useState } from 'react'
import product from "../assets/11634631.png"
import advisory from "../assets/12831871.png"
import service from "../assets/43192591.png"
import blockchain from "../assets/blockchain.png"
import AI from "../assets/AI.png"
import omni from "../assets/omni.png"
import ARVR from "../assets/ARVR.png"
import bigdata from "../assets/bigdata.png"
import IOT from "../assets/IOT.png"
import dotcircle from '../assets/dotcircle.png'
import '../Components/Services.css'
import { Collapse } from 'react-bootstrap'
import industry from "../assets/industry.png"
import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import { Arabic } from "../Language/Arabic"

function Services() {
    const Language = useSelector((state) => state.changer.Language);

    const [open, setOpen] = useState([false, false, false, false, false, false, false, false, false, false]);

    const buttonchange = (index) => {
        if (open[index] === true) {
            open[index] = false
            setOpen([...open])
        } else {
            open.map((data, index) => {
                if (data === true) {
                    open[index] = false
                }
            })
            open[index] = true
            setOpen([...open])
        }
    }

    return (
        <div id="services" className='main-div' dir={Language == "AR" ? "rtl" : "ltr"}>
            <div className=' row main-div d-flex flex-column'>
                <div >
                    <img class="rounded float-end" src={dotcircle} alt="bottomdot" />
                </div>
                <div className='col-12 d-md-flex  flex-row justify-content-between product'>
                    <div className='col-md-3 ml-5  flex-column ml-2 mr-4' >
                        <div style={{ textAlign: 'center' }}>
                            <img src={product} style={{ width: "80px", height: "80px", marginTop: "10px" }} alt={product} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.PRODUCTS : "PRODUCTS"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[0]} onClick={() => buttonchange(0)}
                                style={{ backgroundColor: "#ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div className='container'>
                            <div class='card togglecard '>
                                <Collapse in={open[0]}>
                                    <div id="example-collapse-text" style={{ color: "#455A64", fontWeight: 600, textAlign: "Center", fontSize: "16px ", backgroundColor: "#ECF8FF" }} >
                                        {Language == "AR" ? Arabic.PRODUCTSDetail : "Built with a Blockchain backbone, our products are designed and perfected over the years to suit the various business needs of our customers. We have had multiple customers using our products in varied industries from Healthcare to Manufacturing to Government services."}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 flex-column  advisory'>
                        <div style={{ textAlign: "Center" }}>
                            <img src={advisory} style={{ width: "80px", height: "80px", textAlign: "Center", marginTop: "10px" }} alt={advisory} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>
                                {Language == "AR" ? Arabic.ADVISORYCONSULTING : "ADVISORY CONSULTING"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[1]} onClick={() => buttonchange(1)} style={{ backgroundColor: "#ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[1]}>
                                <div id="example-collapse-text">
                                    <div style={{ backgroundColor: "#ECF8FF", color: "#455A64", fontWeight: 600, fontSize: "16px" }} className="sercontent"> <span className='span2 ml-1'>
                                        {Language == "AR" ? Arabic.ADVISORYCONSULTINGDetail : "Our expert consultants provide a litany of high-end consulting for our customers in various areas:"}
                                        <ul style={{ display: "table", margin: " 0 auto", fontWeight: 600, fontSize: "16px " }}>
                                            <li> {Language == "AR" ? Arabic.ADVISORYCONSULTINGL1 : "Business consulting"}</li>
                                            <li> {Language == "AR" ? Arabic.ADVISORYCONSULTINGL2 : "Technology Consulting"}</li>
                                            <li> {Language == "AR" ? Arabic.ADVISORYCONSULTINGL3 : "Digital transformation"}</li>
                                            <li> {Language == "AR" ? Arabic.ADVISORYCONSULTINGL4 : "Emerging technologies training"}</li>
                                        </ul>
                                    </span></div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className='col-md-3 flex-column ml-2 ml-2'>
                        <div style={{ textAlign: "Center" }}>
                            <img src={service} style={{ width: "80px", height: "80px", marginTop: "10px" }} alt={service} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.PROFESSIONALSERVICES : "PROFESSIONAL SERVICES"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[2]} onClick={() => buttonchange(2)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[2]}>
                                <div id="example-collapse-text">
                                    <div style={{ backgroundColor: "#ECF8FF", color: "#455A64", fontWeight: 600, fontSize: "16px " }} className="sercontent">
                                        <span className='span3'>
                                            {Language == "AR" ? Arabic.PROFESSIONALSERVICESDetail : "We also provide specialized recruitment solutions for our valued customers in the following areas:"}
                                            <ul style={{ display: "table", margin: " 0 auto", fontWeight: 600, fontSize: "16px " }}>
                                                <li>{Language == "AR" ? Arabic.PROFESSIONALSERVICESL1 : "Data Sciences"}</li>
                                                <li>{Language == "AR" ? Arabic.PROFESSIONALSERVICESL2 : "Business Analytics"}</li>
                                                <li>{Language == "AR" ? Arabic.PROFESSIONALSERVICESL3 : "Digital Analytics"}</li>
                                                <li>{Language == "AR" ? Arabic.PROFESSIONALSERVICESL4 : "Life Sciences"}</li>
                                                <li>{Language == "AR" ? Arabic.PROFESSIONALSERVICESL5 : "Digital Transformation"}</li>
                                            </ul>
                                        </span></div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className='col-md-3 flex-column ml-2 mx-2'>
                        <div style={{ textAlign: "Center" }}>
                            <img src={industry} style={{ width: "80px", height: "80px", marginTop: "10px" }} alt={industry} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATION : "industrial process automation"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[3]} onClick={() => buttonchange(3)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[3]}>
                                <div id="example-collapse-text">
                                    <div style={{ backgroundColor: "#ECF8FF", color: "#455A64", fontWeight: 600, fontSize: "16px " }} className="sercontent">
                                        <span className='span3'>
                                            {Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATIONDetail : "We have expertise to create flawless manufacturing processes and provide real-time feedback of requirement changes and provide information at a single source. Benefits include"}
                                            <ul style={{ display: "table", margin: " 0 auto", fontWeight: 600, fontSize: "16px " }}>
                                                <li>{Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATIONL1 : "More accurate capture of cost-information"}</li>
                                                <li>{Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATIONL2 : "Increased uptime"}</li>
                                                <li>{Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATIONL3 : "Incorporate paperless workflow activities"}</li>
                                                <li>{Language == "AR" ? Arabic.INDUSTRIALPROCESSAUTOMATIONL4 : "Manufacturing operations traceability"}</li>
                                            </ul>
                                        </span></div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div className='col-12 ddd d-md-flex  flex-row justify-content-around product' >
                    <div className='col-md-4 ml-5  flex-column blockchain' >
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <img src={blockchain} style={{ width: "80px", height: "80px", marginTop: "10px" }} alt={blockchain} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.BLOCKCHAIN : "BLOCKCHAIN"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[4]} onClick={() => buttonchange(4)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div className='container'>
                            <div class='card togglecard'>
                                <Collapse in={open[4]}>
                                    <div id="example-collapse-text text-center" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                        {Language == "AR" ? Arabic.BLOCKCHAINDetail : "Automated Insurance, Healthcare Records, Supply Chain, Gov-Chains, Digital Assets & Storage , Tokens & Exchanges."}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 flex-column artificial'>
                        <br />
                        <div style={{ textAlign: "Center" }}>
                            <img src={AI} style={{ width: "80px", height: "80px", marginTop: "10px" }} alt={AI} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.ARTIFICIALINTELLIGENCE : "ARTIFICIAL INTELLIGENCE"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[5]} onClick={() => buttonchange(5)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"}</button>
                        </div>
                        <div className='container'>
                            <div class='card togglecard'>
                                <Collapse in={open[5]}>
                                    <div id="example-collapse-text" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                        {Language == "AR" ? Arabic.ARTIFICIALINTELLIGENCEDetail : "Chat Bots , Robotics, Virtual Assistance, Automated machines, Machine Learning, Predictive Analysis."}
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 flex-column omni '>
                        <br />
                        <div style={{ textAlign: "Center" }}>
                            <img src={omni} style={{ width: "80px", height: "80px", marginTop: "20px" }} alt={omni} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.OMNICHANNEL : "OMNI CHANNEL"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[6]} onClick={() => buttonchange(6)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"}</button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[6]}>
                                <div id="example-collapse-text" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                    {Language == "AR" ? Arabic.OMNICHANNELDetail : "Ecommerce apps, Payment Solutions, Marketplace apps, Social Commerce, Banking-as-a-Service, Point-of-Sale apps."}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
                <div className='col-12 ddd d-md-flex  flex-row justify-content-between product' >
                    <div className='col-md-4 flex-column iot'>
                        <br />
                        <div style={{ textAlign: "Center" }}>
                            <img src={IOT} style={{ width: "80px", height: "80px", marginTop: "20px" }} alt={IOT} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.INTERNETOFTHINGS : "Internet of Things"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[7]} onClick={() => buttonchange(7)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[7]}>
                                <div id="example-collapse-text" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                    {Language == "AR" ? Arabic.INTERNETOFTHINGSDetail : "Hardware integrations, Tracking & Analysis, Remote control, SmartThings, Navigation Apps, Performance & Assessment."}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className='col-md-4 flex-column ml-2 ml-2'>
                        <br />
                        <div style={{ textAlign: "Center" }}>
                            <img src={bigdata} style={{ width: "80px", height: "80px", marginTop: "20px" }} alt={bigdata} />
                        </div>
                        <div style={{ textAlign: "Center" }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.BIGDATA : "Big Data"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[8]} onClick={() => buttonchange(8)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[8]}>
                                <div id="example-collapse-text" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                    {Language == "AR" ? Arabic.BIGDATADetail : "Data Analytics, Data Management, BI, NLP, Predictive Analytics, Machine Learning, Data Warehousing."}
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className='col-md-4 ml-5  flex-column arvr' >
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <img src={ARVR} style={{ width: "80px", height: "80px", marginTop: "20px" }} alt={ARVR} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <label className='headfont'>{Language == "AR" ? Arabic.ARVR : "AR/VR"}</label>
                        </div>
                        <br />
                        <div class=" subcasebtn1">
                            <button type="button" aria-controls="example-collapse-text" aria-expanded={open[9]} onClick={() => buttonchange(9)} style={{ backgroundColor: " #ECF8FF", border: "1px solid black" }} class="btn btn-light btn-sm">{Language == "AR" ? Arabic.Knowmore : "Know More"} </button>
                        </div>
                        <div class='card togglecard'>
                            <Collapse in={open[9]}>
                                <div id="example-collapse-text" style={{ backgroundColor: "#ECF8FF", textAlign: "Center", color: "#455A64", fontWeight: 600, fontSize: "16px " }}>
                                    {Language == "AR" ? Arabic.ARVRDetail : "OCR, Virtual Reality, 3D & Animations, Sensors & Control, Ledger & Tokens, Context & Learning, Image Processing."}
                                </div>
                            </Collapse>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default Services