import React from "react";
import '../Components/Terms.css'
import { useNavigate } from 'react-router-dom'
import Header from './Header';
import map from '../assets/world_map.png'

const Terms = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div class="container background">
        <div class="text-white">
          <img src={map} class="card-img" alt={map} />
        </div>
        <div class="card-img-overlay">
          <div class="row">
            <div class="col">
              <div className='policy pt-5'>
                <h1>TERMS & CONDITIONS</h1>
              </div>
              <div class="card text-center col-md-7 " id="pp-container" >
                <div className="col-md-11 text-start p-5" id="ptag">
                  <h5 className="headingterms">Terms</h5>
                  <p className="termpara">Rapidqube WLL (“we” or “us”) owns and operates the website at bahrainfintechbay.com (the “Site”), where you can find information about our products and services. These Website Terms and Conditions (the “Website Terms”) describe the rights and obligations of an unregistered website user or visitor (“user” or “you”) in connection with your use of the Site. By accessing or using the Site in any way, including as an unregistered website visitor, you agree to be bound by these Website Terms and our Privacy Policy, which is available on the Site. These Website Terms apply only to your use of the Site, and the content made available on or through the Site, as an unregistered website user or visitor.  From time to time, we may make modifications, deletions or additions to the Site or these Website Terms. Your continued use of the Site following the posting of any changes to the Website Terms constitutes acceptance of those changes. </p>
                  <h5 className="headingterms">Eligibility</h5>
                  <p className="termpara">The Site and services it describes are available only to individuals who are at least 16 years old, unless we specify otherwise. No one under this age may access or use the Site or provide any personal information through the Site.</p>
                  <h5 className="headingterms">Content</h5>
                  <p className="termpara">The text, images, videos, audio clips, software and other content generated, provided, or otherwise made accessible on or through the Site (collectively, “Content”) are contributed by us. We retain all proprietary rights in the Site and the Content made available on or through the Site, and, except as expressly set forth in these Website Terms, no rights are granted to any Content. Subject to these Website Terms, we grant each user of the Site a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for viewing, browsing and using the functionality of the Site. All Content is for general informational purposes only. We reserve the right, but do not have any obligation to monitor, remove, edit, modify or remove any Content, in our sole discretion, at any time for any reason or for no reason at all.</p>
                  <h5 className="headingterms">Disclaimer; Limitation of liability</h5>
                  <p className="termpara">To the extent permitted by law, we disclaim all warranties and terms, express or implied, with respect to the Site, Content or services (including third party services) on or accessible through the Site, including any warranties or terms of merchantability, fitness for a particular purpose, title, non-infringement and any implied warranties, or arising from course of dealing, course of performance or usage in trade. In no event shall Rapidqube Parties be liable under contract, tort, strict liability, negligence or any other legal or equitable theory with respect to the Site for (a) any special, indirect, incidental, punitive, compensatory or consequential damages of any kind whatsoever (however arising) or (b) damages in excess of (in the aggregate) BD$100.</p>
                  <h5 className="headingterms">Miscellaneous</h5>
                  <p className="termpara">These Website Terms shall be governed by and construed in accordance with the laws of Kingdom of Bahrain, excluding its conflicts of law rules. These Website Terms constitute the entire agreement between us regarding the Site and supersedes and merges any prior proposals, understandings and contemporaneous communications. If any provision of these Website Terms is held to be invalid or unenforceable, that provision shall be limited or eliminated to the minimum extent necessary so that these Website Terms shall otherwise remain in full force and effect and enforceable. In order for any waiver of compliance with these Website Terms to be binding, we must provide you with written notice of such waiver. The failure of either party to enforce its rights under these Website Terms at any time for any period will not be construed as a waiver of such rights.</p>
                  <p className="termpara">If you have any questions, complaints, or claims with respect to the Site, you may email us at info@rapidqube.bh</p>
                </div>
                <div class=" subcasebtn1">
                  <button type="submit" class="btn btn-dark " onClick={() => { navigate("/") }}>
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Terms;