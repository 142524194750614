import React, { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import mylogo from "../assets/mylogo.png";
import "../Components/Header.css";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { arabic, english } from "../Redux/ChangeLanguage"

import { Arabic } from "../Language/Arabic"

function Header() {

  const Language = useSelector((state) => state.changer.Language);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  console.log("location", window.location.pathname);

  const goto = (path) => {
    navigate("/");
    localStorage.setItem("path", path);
  };

  useEffect(() => {
    if (window.location.pathname !== "/") {
      localStorage.setItem("path", window.location.pathname);
    } else {
      console.log("path", localStorage.getItem("path"));
      setTimeout(() => {
        let offsetTop = document.getElementById(localStorage.getItem("path")).offsetTop;
        window.scrollTo({
          top: offsetTop - 100,
          behavior: "smooth"
        });
        localStorage.removeItem("path");
      }, 1000);
    }
  }, [])

  useEffect(() => {
    fetch('https://extreme-ip-lookup.com/json/?key=eiiffQ50HpdKo5eLiK5z')
      .then(res => res.json())
      .then(response => {
        console.log("Country is : ", response.country);
        if (response.country == "Bahrain") {
          dispatch(arabic())
        }
      })
      .catch((data, status) => {
        console.log('Request failed:', data);
      });
  }, [])

  useEffect(() => {
    console.log("Language", Language)
  }, [Language]);

  return (
    <div id="about">
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="light"
        variant="light"
        fixed="top"
      >
        <Navbar.Brand href="#home">
          <div className="mylogo1">
            <img src={mylogo} alt="mylogo" className="mylogo" width={250} />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav " />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="subdiv">
            <Nav.Link >
              {window.location.pathname !== "/" ? (
                <div onClick={() => goto("about")}>{Language == "AR" ? Arabic.About : "About"}</div>
              ) : (
                <Link to="about" smooth={true} duration={100}>
                  {Language == "AR" ? Arabic.About : "About"}
                </Link>
              )}
            </Nav.Link>
            <Nav.Link>
              {window.location.pathname !== "/" ? (
                <div onClick={() => goto("leadership")}>{Language == "AR" ? Arabic.Team : "Team"}</div>
              ) : (
                <Link to="leadership" smooth={true} duration={100}>
                  {Language == "AR" ? Arabic.Team : "Team"}
                </Link>
              )}
            </Nav.Link>

            <Nav.Link>
              {window.location.pathname !== "/" ? (
                <div onClick={() => goto("service")}>{Language == "AR" ? Arabic.SERVICES : "SERVICES"}</div>
              ) : (
                <Link to="service" smooth={true} duration={100}>
                  {Language == "AR" ? Arabic.SERVICES : "SERVICES"}
                </Link>
              )}
            </Nav.Link>
            <Nav.Link>
              {window.location.pathname !== "/" ? (
                <div onClick={() => goto("casestudy")}>{Language == "AR" ? Arabic.PRODUCTS : "PRODUCTS"}</div>
              ) : (
                <Link to="casestudy" smooth={true} duration={100}>
                  {Language == "AR" ? Arabic.PRODUCTS : "PRODUCTS"}
                </Link>
              )}
            </Nav.Link>
            <Nav.Link>
              <div onClick={() => navigate("/career")}>{Language == "AR" ? Arabic.CAREERS : "CAREERS"}</div>
            </Nav.Link>
            <Nav.Link>
              {window.location.pathname !== "/" ? (
                <div onClick={() => goto("contactus")}>{Language == "AR" ? Arabic.CONTACT : "CONTACT"}</div>
              ) : (
                <Link to="contactus" smooth={true} duration={100}>
                  {Language == "AR" ? Arabic.CONTACT : "CONTACT"}
                </Link>
              )}
            </Nav.Link>
            <Nav.Link >
                <div className="g-0" >
                  <select class="LanguageDropDown" id="Language" name="Language" onChange={(e) => {
                    if(e.target.value === "English"){
                      dispatch(english())
                    }
                    else if(e.target.value === "Arabic"){
                      dispatch(arabic())
                    }
                  }}>
                    <option class="SelectedLang" value="English" selected={Language == "EN"? true: false}>EN</option>
                    <option  class="SelectedLang" value="Arabic" selected={Language == "AR"? true: false}>AR</option>
                  </select>
                </div>

            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
