import React from 'react'
import group2 from "../assets/Mask group2.png";
import LinkedIn from "../assets/LinkedIn.png";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

function SubLeader2(props) {
    const Language = useSelector((state) => state.changer.Language);

    return (
        <div class="container">
            <div class="row ">
                <div class="col-lg-12">
                    <div class="card subcontants" >
                        <div class="cardicon">
                            <HighlightOffIcon style={{ fontSize: 30, color: "#A9A4A4", cursor: "pointer" }}
                                onClick={() => props.datas(0)} />
                        </div>
                        <div class="container">
                            <div class="row subcontants ">
                                <div class="col-md-4 ">
                                    <div >
                                        <img src={group2} class="card-img " style={{ width: "15rem" }} alt="..." />
                                        <div class="text-center " >
                                            <br />
                                            <h4>{Language == "AR" ? Arabic.Muthu : "Muthuprakash Ravindran"}</h4>
                                            <p>{Language == "AR" ? Arabic.MuthuDes : "Vice President"}</p>
                                            <img src={LinkedIn}
                                                alt="LinkedIn"
                                                onClick={() => window.open("https://www.linkedin.com/in/muthu-prakash-ravindran-pmp-csm-4570854/")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 leadercontantss">
                                    <div class="container ">
                                        <p>
                                            {Language == "AR" ? Arabic.MuthuDesc : "Understanding the business needs and pain points of the customers comes natural with his years of experience in delivering complex systems for various customers and in being part of the solution to various levels of understanding how production works in different businesses. Today, working with a lot of government agencies and customers across the world, Muthu helps them in efficiently running their businesses and in helping with a predictable delivery experience."}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubLeader2