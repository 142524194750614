import { Button } from 'react-bootstrap';
import '../Components/Screen4.css';
import { Carousel } from 'react-bootstrap';
import group1 from '../assets/group1.png'
import group2 from '../assets/group2.png'
import group6 from '../assets/group6.png'
import group3 from '../assets/group3.png'
import group4 from '../assets/group4.png'
import group5 from '../assets/group5.png'
import sidegreendot from '../assets/sidegreendot.png'
import { useNavigate } from 'react-router-dom'
import {useEffect} from 'react';
import { useSelector } from "react-redux";
import {Arabic} from "../Language/Arabic"

function Screen4() {
  const navigate = useNavigate();
  const Language = useSelector((state) => state.changer.Language);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='my-5' id="career" dir={Language == "AR"? "rtl" : "ltr"}>
      <div>
        <div class="text-center">
          <div class="card-body">
            <h5 class="card-title ourtitle">{Language == "AR"? Arabic.MissViss1 : "Our Promise to stakeholders"}</h5>
            <p class="card-text paras">{Language == "AR"? Arabic.MissViss2 : "Creating meaningful acquaintances between Business and People powered by Digital"}</p>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-6">
            <div class="row ">
              <div class="col ">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="imgstyle1 ">
                      <Carousel>
                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group1}
                            alt="Second slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group2}
                            alt="Third slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group6}
                            alt="Third slide"
                          />
                        </Carousel.Item>

                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group3}
                            alt="Third slide"
                          />
                        </Carousel.Item>


                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group4}
                            alt="Third slide"
                          />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                          <img
                            className="d-block w-100"
                            src={group5}
                            alt="Third slide"
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 vertical-center">
            <div class="card-body ">
              <h5 class="card-title title">{Language == "AR"? Arabic.MISSION : "Mission"}</h5>
              <p class="card-text">{Language == "AR"? Arabic.MISSIONDetail : "As a trusted advisor, our mission is to create breakthrough products and solutions that help solve complex business challenges by building secure, dependable and efficient digital applications, systems and modern sustainable infrastructure."}</p>
              <h5 class="card-title Title">{Language == "AR"? Arabic.VISION : "Vision"}</h5>
              <p class="card-text">{Language == "AR"? Arabic.VISIONDetail : "Our vision is to help build sustainable businesses that can embrace evolving needs powered by transformative technologies that unlock growth for businesses and investors across the globe."}</p>
              <div class="CareerBtn">
                <Button variant="dark career" onClick={() => { navigate("/career") }}>{Language == "AR"? Arabic.CAREERS : "Careers"}</Button>
              </div>
              <div className='sidegreendot' >
                <img class="rounded float-end" src={sidegreendot} alt="sidegreendot" width={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}


export default Screen4