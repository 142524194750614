import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Components/Contactform.css";
import { useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import map from "../assets/world_map.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic";

const Contactus = () => {
  const navigate = useNavigate();
  const Language = useSelector((state) => state.changer.Language);
  const form = useRef();
  const notify = () =>
    toast("Message Sent Successfully! We will get back to you soon.");
  const errnotify = () => toast("Message Sending Failed! Please try again.");

  const sendMail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs
      .sendForm(
        "service_zp2f6pg",
        "template_38046v9",
        form.current,
        "d4X-gxH2SQcr9RQs5"
      )
      .then(
        (result) => {
          console.log("Mail....");
          notify();
          console.log(result.text);
        },
        (error) => {
          errnotify();
          console.log(error.text);
        }
      );
  };

  console.log("from", form.current);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <Header />
      <div className="contactformmain" dir={Language == "AR" ? "rtl" : "ltr"}>
        <div class="text-white">
          <img src={map} class="card-img" alt={map} />
        </div>
        <div class="card-img-overlay">
          <div className="contact_us pt-5">
            <h1>{Language == "AR" ? Arabic.CONTACTUS : "Contact Us"}</h1>
          </div>
          <div class="container">
            <div class="row justify-content-md-center">
              <div class=" col-md-8">
                <div className="contactform">
                  <form ref={form} onSubmit={sendMail}>
                    <div>
                      <label for="exampleInputEmail1" class="form-label">
                        {Language == "AR" ? Arabic.Name : "Name*"}
                      </label>
                      <input
                        required
                        type="text"
                        name="Name"
                        class="form-control"
                        aria-describedby="emailHelp"
                        id="inputbox"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {Language == "AR" ? Arabic.Email : "Email*"}
                      </label>
                      <input
                        required
                        type="email"
                        class="form-control"
                        id="inputbox"
                        name="email"
                        
                      />
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {Language == "AR"
                          ? Arabic.Mobilenumber
                          : "Mobile Number*"}
                      </label>
                      <input
                        required
                        type="number"
                        class="form-control"
                        id="inputbox"
                        name="Phone"
                       
                      />
                    </div>

                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {Language == "AR"
                          ? Arabic.Organization
                          : "Organization*"}
                      </label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        id="inputbox"
                        name="Organization"
                        
                      />
                    </div>
                    <div class="mb-3 ">
                      <label
                        for="exampleInputPassword1"
                        class="form-label me-3"
                      >
                        {Language == "AR" ? Arabic.Intention : "Intention*"}
                      </label>
                      <select
                        id="cars"
                        name="Intention"
                       
                      >
                        <option value="Dicussion">
                          {Language == "AR" ? Arabic.Intention1 : "Discussion"}
                        </option>
                        <option value="Query">
                          {Language == "AR" ? Arabic.Intention2 : "Query"}
                        </option>
                        <option value="Job">
                          {Language == "AR" ? Arabic.Intention3 : "Job"}
                        </option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        {Language == "AR" ? Arabic.Message : "Message"}
                      </label>
                      <textarea
                        class="form-control"
                        rows="5"
                        id="textaream"
                        name="message"
                        
                      ></textarea>
                    </div>
                    <div class="text-center ps-4">
                      <button
                        type="submit"
                        style={{ border: "1px solid black", fontSize: "18px" }}
                        class="btn btn-dark btn-sm me-2"
                      >
                        {Language == "AR" ? Arabic.SEND : "SEND"}
                      </button>
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid black",
                          fontSize: "18px",
                        }}
                        class="btn btn-light btn-sm "
                        onClick={() => navigate(-1)}
                      >
                        {Language == "AR" ? Arabic.BACK : "Back"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default Contactus;
