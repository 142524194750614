import React from "react";
import '../Components/PrivacyPolicy.css'
import map from '../assets/world_map.png'
import { useNavigate } from 'react-router-dom'
import Header from './Header';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
    <Header/>
      <div class="container">
        <div class="text-white">
          <img src={map} class="card-img" alt={map}/>
        </div>
        <div class="card-img-overlay">
          <div class="row">
            <div class="col">
              <div className='policy pt-5'>
                <h1>PRIVACY POLICY</h1>
              </div>
              <div class="card text-center col-md-7 " id="pp-container" >
                <div className="col-md-12 text-start p-5 ptag" >
                  <p className="termpara">RapidQube Digital Solutions WLL here in after referred to as ‘Rapidqube Bahrain,’ is committed to respecting your privacy and choices while using our website. We are committed to protecting the confidentiality of the data shared with us by our customers. The statement highlights our privacy practices regarding Personal Information that we hold about you.</p>
                  <h5 className="headingterms">Domains and websites for this policy</h5>
                  <p className="termpara">For this policy, the term “Websites” shall refer collectively to<a className="link"> https://www.rapidqube.bh/ </a>as well as the other Websites, Social Media, and Blogs that the Rapidqube Bahrain group operates and that link to this policy.</p>
                  <h5 className="headingterms">I. What Information Is Collected and How We Use This Data?</h5>
                  <p className="termpara">For this privacy statement, ‘Personal Information which may have RapidQube Bahrain such as your IP address, demographics, your computers’ operating system, and browser type may be collected to provide better usability, troubleshooting, site maintenance, and to understand which parts of the website are visited and how frequently. To provide relevant content based on your preferences, when you request information on our website, we require you to provide us with certain Personal Information such as your name, email address, telephone number, etc.</p>
                  <h5 className="headingterms">II. Consequences of not providing Personal Information </h5>
                  <p className="termpara">If you choose not to provide your mandatory Personal Information, we may not be able to provide the corresponding service.</p>
                  <h5 className="headingterms">III. Data recipients, transfer, and disclosure of Personal Information</h5>
                  <p className="termpara">RapidQube Bahrain does not share your Personal Information with third parties for marketing purposes without seeking your prior permission. We may share your Personal Information within RapidQube Bahrain or with its affiliates, business partners, service vendors, authorized third-party agents, or contractors located in any part of the world after ensuring that such entities are contractually bound by data privacy obligations.</p>
                  <h5 className="headingterms">IV. Use of cookies </h5>
                  <p className="termpara">We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on the preferences you have specified.</p>
                  <h5 className="headingterms">V. Access, correction, the objection of your Personal Information </h5>
                  <p className="termpara">Depending on local law, you may access, update, or correct the Personal Information that we hold. For this, you may contact us by email at info@rapidqube.bh</p>
                  <h5 className="headingterms">VI. Data Storage And Retention</h5>
                  <p className="termpara">RapidQube Bahrain shall retain your data unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
                  <p className="termpara">RapidQube Bahrain implements security controls to prevent breaches and utilizes appropriate physical, technical, and administrative procedures to safeguard the information it collects. RapidQube Bahrain subjects our services to internationally recognized certification and attestation standards.</p>
                  <h5 className="headingterms">VIII. Limits of our policy  </h5>
                  <p className="termpara">RapidQube Bahrain may provide links to third-party websites and services. However, RapidQube Bahrain is not responsible for the privacy statements, practices, or the contents of such third-party websites.</p>
                  <h5 className="headingterms">IX. How to contact us </h5>
                  <p className="termpara">If you have any questions regarding our privacy practices or this privacy statement, you may contact us by email at <a className="link"> info@rapidqube.bh </a></p>
                  <h5 className="headingterms">X. Change to our policy </h5>
                  <p className="termpara">RapidQube Bahrain may change the data privacy practices and update this privacy statement as and when the need arises, and the same will be made available on the website. But our commitment to protecting the privacy of website users will continue to remain.</p>
                </div>
                <div class=" subcasebtn1">
                  <button type="submit" class="btn btn-dark " onClick={() => { navigate("/") }}>
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy;