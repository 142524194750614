import React, { useState } from 'react';
import web from '../assets/web.png'
import web1 from '../assets/web1.png'
import web2 from '../assets/web2.png'
import web3 from '../assets/web3.png'
import web4 from '../assets/web4.png'
import web5 from '../assets/web5.png'
import web6 from '../assets/web6.png'
import web7 from '../assets/web7.png'
import web8 from '../assets/web8.png'
import web9 from '../assets/web9.png'
import web10 from '../assets/web10.png'
import web11 from '../assets/web11.png'
import web12 from '../assets/web12.png'
import web13 from '../assets/web13.png'
import web14 from '../assets/web14.png'
import web15 from '../assets/web15.png'
import web16 from '../assets/web16.png'
import web17 from '../assets/web17.png'
import web18 from '../assets/web18.png'
import web19 from '../assets/web19.png'
import web20 from '../assets/web20.png'
import ai1 from '../assets/ai1.png'
import ai2 from '../assets/ai2.png'
import ai3 from '../assets/ai3.png'
import ai4 from '../assets/ai4.png'
import ai5 from '../assets/ai5.png'
import ai6 from '../assets/ai6.png'
import ai7 from '../assets/ai7.png'
import iot1 from '../assets/iot1.png'
import iot2 from '../assets/iot2.png'
import iot3 from '../assets/iot3.png'
import cloud1 from '../assets/cloud1.png'
import cloud2 from '../assets/cloud2.png'
import cloud3 from '../assets/cloud3.png'
import cloud4 from '../assets/cloud4.png'
import cloud5 from '../assets/cloud5.png'
import cloud6 from '../assets/cloud6.png'
import cloud7 from '../assets/cloud7.png'
import cloud8 from '../assets/cloud8.png'
import cloud9 from '../assets/cloud9.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"

function Techstack() {
    const [select, setSelect] = useState("web")
    const Language = useSelector((state) => state.changer.Language);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const responsivebtn = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className='techstack' >
            <div class="techno">
                <h6>{Language == "AR" ? Arabic.TECHNOLOGIES : "technologies"}</h6>
                <h1>{Language == "AR" ? Arabic.TechStacks : "Tech stacks"}</h1>
            </div>
            <div class="row justify-content-md-center holebtn">
                <div class="col col-lg-10 text-center">
                    <div class="row justify-content-md-around text-center">
                        <div class="techstylename ">
                            <button style={select === "web" ? { color: "white", backgroundColor: "#358EC5", border: "1px solid black" } : { border: "1px solid black" }} href="" onClick={() => setSelect("web")}>
                                {Language == "AR" ? Arabic.TechStack1 : "Web 3.0"}
                            </button>
                        </div>
                        <div class=" techstylename ">
                            <button style={select === "ai" ? { color: "white", backgroundColor: "#358EC5", border: "1px solid black" } : { border: "1px solid black" }} href="" onClick={() => setSelect("ai")}>
                                {Language == "AR" ? Arabic.TechStack2 : "AI"}
                            </button>
                        </div>
                        <div class=" techstylename">
                            <button style={select === "iot" ? { color: "white", backgroundColor: "#358EC5", border: "1px solid black" } : { border: "1px solid black" }} href="" onClick={() => setSelect("iot")}>
                                {Language == "AR" ? Arabic.TechStack3 : "IOT"}
                            </button>
                        </div>
                        <div class=" techstylename">
                            <button style={select === "cloudops" ? { color: "white", backgroundColor: "#358EC5", border: "1px solid black" } : { border: "1px solid black" }} href="" onClick={() => setSelect("cloudops")}>
                                {Language == "AR" ? Arabic.TechStack4 : "CloudOps"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {select === "web" ?
                <div className='techcarousel'>
                    <Carousel responsive={responsive} centerMode={true}>
                        <div class=" techstyle pt-3">
                            <img src={web} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle ">
                            <img src={web1} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web2} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web3} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web4} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web5} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web6} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web7} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web8} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web9} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web10} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web11} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web12} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web13} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web14} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web15} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web16} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web17} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web18} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={web19} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle ">
                            <img src={web20} class="card-img" alt="..." />
                        </div>
                    </Carousel>
                </div>
                : null
            }
            {select === "ai" ?
                <div className='techcarousel'>
                    <Carousel responsive={responsive} centerMode={true}>
                        <div class=" techstyle ">
                            <img src={ai1} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai2} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai3} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai4} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai5} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai6} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={ai7} class="card-img" alt="..." />
                        </div>
                    </Carousel>
                </div>
                : null
            }
            {select === "iot" ?
                <div className='techcarousel'>
                    <Carousel responsive={responsive} centerMode={true}>
                        <div class=" techstyle ">
                            <img src={iot1} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={iot2} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={iot3} class="card-img" alt="..." />
                        </div>
                    </Carousel>
                </div>
                : null
            }
            {select === "cloudops" ?
                <div className='techcarousel'>
                    <Carousel responsive={responsive} centerMode={true}>
                        <div class=" techstyle ">
                            <img src={cloud1} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud2} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud3} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle ">
                            <img src={cloud4} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud5} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud6} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle ">
                            <img src={cloud7} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud8} class="card-img" alt="..." />
                        </div>
                        <div class=" techstyle">
                            <img src={cloud9} class="card-img" alt="..." />
                        </div>
                    </Carousel>
                </div>
                : null
            }
        </div>
    )
}

export default Techstack;