import React from 'react'
import testimonial from '../assets/testimonial.png';
import testimonial2 from '../assets/testimonial2.png';
import testimonial3 from '../assets/testimonial3.png';
import eterna from '../assets/eterna.png';
import Logo2 from '../assets/Logo2.png';
import Logo3 from '../assets/Logo3.png';
import '../Components/Testmonial.css'
import Carousel from 'react-multi-carousel';
import { useSelector } from "react-redux";
import { Arabic } from "../Language/Arabic"
import contactusimg1 from '../assets/contactusimg1.png'
import contactusimg2 from '../assets/contactusimg2.png'
import contactusimg3 from '../assets/contactusimg3.png'


const Testmonial = () => {
    const Language = useSelector((state) => state.changer.Language);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div class="testmonialheader">
            <div className='testmonial'>
                <br /><br /><br /><br />
                <h6>{Language == "AR" ? Arabic.Testimonials : "testimonial"}</h6>
                <h1>{Language == "AR" ? Arabic.ClientFeedbacks : "Client feedbacks"}</h1>
            </div>
            <br /><br />
            <div class="casecaro ">
                <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000}>
                    <div class="row justify-content-md-center">
                        <div class="col-4">
                            <div >
                                <img
                                    className="itemContent"
                                    src={testimonial}
                                    alt="testimonial"
                                />
                            </div>
                        </div>
                        <div class="col-md-8 ">
                            <div className='eterna'>
                                <img src={eterna}
                                    alt="eterna"
                                />
                                <h5 class="card-title45 title90">Eterna Services</h5>
                            </div>
                            <div>
                                <div class="card-body2">
                                    <p class="card-text45">RapidQube team has the right balance of technical & domain expertise because of which they always provide best-fit solutions across industries.
                                        We admire the level of understanding of customer challenges and solutions. We are thoroughly convinced about their Technical Capability, flexibility, and Transparency.
                                        They have a very professional, hard-working, and committed team. We would most definitely work with them for our upcoming digital projects.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-4">
                            <div >
                                <img
                                    className="itemContent"
                                    src={testimonial2}
                                    alt="testimonial2"
                                />
                            </div>
                        </div>
                        <div class="col-md-8 ">
                            <div className='eterna'>
                                <img src={Logo2}
                                    alt="Logo2"
                                />
                                <h5 class="card-title45 title90">MahaIT</h5>
                            </div>
                            <div>
                                <div class="card-body2 ">
                                    <p class="card-text2">
                                        We are working with RapidQube for more than two years now. We see RapidQube as one of our emerging technology solution providers.
                                        We had a very good experience with RapidQube for our first-ever Blockchain Solution implementation.
                                        RapidQube is having a very dedicated & committed team who is always available to resolve any issues.
                                        At MahaIT, I see we have many opportunities to work together on various use-case in other areas.
                                        We really acknowledge and appreciate their technical expertise and solutions capability.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-4">
                            <div >
                                <img
                                    className="itemContent"
                                    src={testimonial3}
                                    alt="testimonial3"
                                />
                            </div>
                        </div>
                        <div class="col-md-8 ">
                            <div className='eterna'>
                                <img src={Logo3}
                                    alt="Logo3"
                                />
                                <h5 class="card-title45 title90">NISG</h5>
                            </div>
                            <div>
                                <div class="card-body3 ">
                                    <p class="card-text3">
                                        RapidQube is NISG’s preferred partner for capability building of Government officials on emerging technologies such as Blockchain, Data analytics.
                                        The team of RapidQube is adept in fulfilling the needs of the business through meticulous execution of process.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
            <div class="row d-flex justify-content-center" style={{ marginLeft: "80px" }}>
                <div class="col">
                    <div class="row justify-content-md-around">
                        <div class=" contactcardstyle">
                            <img src={contactusimg1}
                                class="card-img"
                                alt="..." />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row justify-content-md-around">
                        <div class=" contactcardstyle">
                            <img src={contactusimg2}
                                class="card-img"
                                alt="..." />
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row justify-content-md-around">
                        <div class=" contactcardstyle">
                            <img src={contactusimg3}
                                class="card-img"
                                alt="..." />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial;
