import './App.css';
import Header from '../src/Components/Header';
import Landingpage from './Components/Landingpage';
import Screen3 from './Components/Screen3';
import Screen4 from './Components/Screen4';
import Career from './Components/Career';
import Casestudy from './Components/Casestudy';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Subcasestudy2 from './Components/Subcasestudy2';
import Subcasestudy3 from './Components/Subcasestudy3';
import Subcasestudy4 from './Components/Subcasestudy4';
import Subcasestudy1 from './Components/Subcasestudy1';
import Subcasestudy from './Components/Subcasestudy';
import Techstack from './Components/Techstack';
import LeaderShip from './Components/LeaderShip';
import Services from './Components/Services';
import Contactus from './Components/Contactus';
import Contactform from './Components/Contactform'
import Clock from './Components/Clock'
import Info from './Components/Info';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Testmonial from './Components/Testmonial';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <div style={{ backgroundColor: 'white' }}>
              <Header />
              <br style={{ backgroundColor: '#ECF8FF' }} />
              <div style={{ backgroundColor: '#ECF8FF' }} className='my-5'>
                <Landingpage />
              </div>
              <br id="service" />
              <br />
              <br />
              <Screen3 />
              <br />
              <br />
              <Services />
              <div id="leadership" style={{ backgroundColor: '#ECF8FF' }}>
                <br />
                <br />
                <br />
                <LeaderShip />
              </div>
              <br />
              <div className='my-5' >
                <Screen4 />
              </div>
              <br id="casestudy" />
              <br />
              <br />
              <div >
                <Casestudy />
              </div>
              <br />
              <br />
              <div>
                <Techstack />
              </div>
              <br />
              <div>
                <Testmonial />
              </div>
              <br />
              <div>
                <Contactus />
              </div>
              <br />
              <div>
                <Clock />
              </div>
              <br />
              <div>
                <Info />
              </div>
              <br />
            </div>
          </>
        }>
        </Route>
        <Route path="/career" element={<Career />} />
        <Route path="/casestudy" element={<Casestudy />} />
        <Route path="/subcase" element={<Subcasestudy />} />
        <Route path="/subcase1" element={<Subcasestudy1 />} />
        <Route path="/subcase2" element={<Subcasestudy2 />} />
        <Route path="/subcase3" element={<Subcasestudy3 />} />
        <Route path="/subcase4" element={<Subcasestudy4 />} />
        <Route path="/contactform" element={<Contactform />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Terms" element={<Terms />} />
      </Routes >
    </BrowserRouter >

  );
}

export default App;
